import { ChangeEvent, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { SortIcon, StarIcon } from 'icons';
import clsx from 'clsx';
import { Drawer, FormControlLabel, Radio } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';

import styles from './OverviewFilesTab.module.scss';
import { FilesExtensionEnum, FilterTabsProps, FoldersStatusEnum, OverviewFilesTabProps } from './types';
import { useHistory } from 'react-router';

export const OverviewFilesTab = ({ isSortingApplied, match, link, isProject = true }: OverviewFilesTabProps) => {
  const { push } = useHistory();
  const {
    params,
    query: { isFavorites = false, status = FoldersStatusEnum.All, type = FilesExtensionEnum.All },
    query,
  } = match;
  const [openFilesSort, setOpenFilesSort] = useState(false);

  const handleOpenFilesSort = () => setOpenFilesSort(true);
  const handleCloseFilesSort = () => setOpenFilesSort(false);

  const handleStatusChange = (status: string) => push(link({ ...query, ...params, status }));
  const handleTypeChange = (type: string) => push(link({ ...query, ...params, type }));
  const handleFavoritesTypeChange = (isFavorites: boolean) => push(link({ ...query, ...params, isFavorites }));

  const handleSortOptionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    push(link({ ...query, ...params, status: value }));
    handleCloseFilesSort();
  };

  const handleExtensionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    handleTypeChange(value);
    handleCloseFilesSort();
  };

  const FilteredFileTab = ({ label }: FilterTabsProps<FoldersStatusEnum>) => {
    return (
      <Chip
        {...(status !== label && { variant: 'outlined' })}
        onClick={() => handleStatusChange(label)}
        color="primary"
        className="capitalize ml-12 mb-12"
        label={label}
      />
    );
  };

  const FileExtension = ({ label }: FilterTabsProps<FilesExtensionEnum>) => {
    return (
      <Chip
        {...(type !== label && { variant: 'outlined' })}
        onClick={() => handleTypeChange(label)}
        color="primary"
        className="mr-12 mb-12 uppercase"
        label={label}
      />
    );
  };

  return (
    <>
      <div className="flex justify-content-between flex-wrap">
        <div className="flex align-items-center my-sm-12">
          <p className="text-smallTextGrey align-items-center mr-16 mb-12 d-none d-sm-block">File format:</p>
          <div className="d-none d-sm-block">
            <FileExtension label={FilesExtensionEnum.All} />
            <Chip
              {...(!isFavorites && { variant: 'outlined' })}
              onClick={() => handleFavoritesTypeChange(!isFavorites)}
              color="primary"
              className="mr-12 mb-12"
              label={
                <>
                  <StarIcon className={clsx(styles.starIcon, isFavorites && styles.activeStarIcon)} />
                  Favorites
                </>
              }
            />
            <FileExtension label={FilesExtensionEnum.Pdf} />
            <FileExtension label={FilesExtensionEnum.Doc} />
            <FileExtension label={FilesExtensionEnum.Xls} />
            <FileExtension label={FilesExtensionEnum.Jpg} />
          </div>
        </div>
        <div className="flex align-items-center justify-content-end my-sm-12">
          <p className="text-smallTextGrey align-items-center mb-12 d-none d-sm-block">File folders:</p>
          <div className="d-none d-sm-block">
            <FilteredFileTab label={FoldersStatusEnum.All} />
            {isProject && <FilteredFileTab label={FoldersStatusEnum.WorkOrder} />}
          </div>
        </div>

        <div
          className="text-smallTextGrey flex align-items-center justify-content-end d-sm-none py-12 c-pointer"
          onClick={handleOpenFilesSort}
        >
          <SortIcon className="mr-4" />
          Show me
          {isSortingApplied && <span className="sortingCountContainer" />}
        </div>
      </div>

      <Drawer anchor="bottom" open={openFilesSort} onClose={handleCloseFilesSort} className="d-sm-none">
        <h3 className="weight-700 mb-20">File folders</h3>

        <RadioGroup aria-label="gender" name="gender1" value={status} onChange={handleSortOptionChange}>
          <FormControlLabel value={FoldersStatusEnum.All} control={<Radio />} label="All" />
          <FormControlLabel value={FoldersStatusEnum.WorkOrder} control={<Radio />} label="Work Order" />
        </RadioGroup>

        <h3 className="weight-700 my-20">File format</h3>
        <RadioGroup aria-label="gender" name="gender1" value={type} onChange={handleExtensionChange}>
          <FormControlLabel value={FilesExtensionEnum.All} control={<Radio />} label="All" />
          <FormControlLabel value={FilesExtensionEnum.Doc} control={<Radio />} label="DOC" />
          <FormControlLabel value={FilesExtensionEnum.Jpg} control={<Radio />} label="JPG" />
          <FormControlLabel value={FilesExtensionEnum.Pdf} control={<Radio />} label="PDF" />
          <FormControlLabel value={FilesExtensionEnum.Xls} control={<Radio />} label="XLS" />
        </RadioGroup>

        <h3 className="weight-700 mt-20"></h3>
        <RadioGroup
          aria-label="gender"
          name="favorite"
          value={isFavorites}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            handleFavoritesTypeChange(value === 'true')
          }
        >
          <FormControlLabel value={true} control={<Radio />} label="Favorite" />
          <FormControlLabel value={false} control={<Radio />} label="All" />
        </RadioGroup>
      </Drawer>
    </>
  );
};
